import { Toast } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import LayoutSSO from '../common/components/LayoutSSO';
import { Tenants } from '../tenants/components/Tenants';
import { Configuration } from '../configuration/components/Configuration';
import { Users } from '../users/components/Users';
import { ImportFiles } from '../import files/components/ImportFiles';
import { JobStatus } from '../job status/components/JobStatus';
import { Report } from '../report/components/Report';
import {pages} from '../utils/Pages';
import { Login } from '../login/components/Login';
import { NotFound } from '../common/components/NotFound';
import './index.css';
import { useMsal } from "@azure/msal-react";
import { getAppInsights } from '../TelemetryService';
import TelemetryProvider from '../telemetry-provider';
import SessionTimeout from '../SessionTimeout';
export let tokenData = {};

export const Routes = (props) => {
    console.log(props);
    const [showToast, setShowToast] = useState(false);
    const [uploadedCount, setUploadedCount] = useState(1);
    const [totalFilesCount, setTotalFilesCount] = useState(0);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const { accounts } = useMsal();
    let [userRole, setUserRole] = useState('');
    const { instance } = useMsal();
    
    useEffect(() => {
        if (accounts.length > 0) {
            setUserRole(JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["med_data conversion service_role"]);
            window.localStorage.setItem('role', JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["med_data conversion service_role"]);
            let role = JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["med_data conversion service_role"];
            let email = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).eMail;
            let orgId = JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["dataConversionService-OrganisationID"];
            let name = JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["dataConversionService-OrganisationName"];
            let userFirstName = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).firstName;
            let userLastName = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).lastName;
            window.localStorage.setItem('email', JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).eMail);
            let userAccountId = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).accountId;
            localStorage.setItem('organizationID', orgId);
            localStorage.setItem('organizationName', name);
            localStorage.setItem('userFirstName', userFirstName);
            localStorage.setItem('userLastName', userLastName);
            localStorage.setItem('userAccountId', userAccountId);
            tokenData.role = role;
            tokenData.email = email;
            tokenData.orgId = orgId;
            tokenData.name = name;
            tokenData.userFirstName = userFirstName;
            tokenData.userLastName = userLastName;
            tokenData.userAccountId = userAccountId;
        }
    }, [accounts]);

    
    const customRedirect = (page) => {
        if (page === 'Tenants') {
            return <Tenants />
        } else if(page === 'Users') {
            return <Users />
        } else if(page === 'Configuration') {
            return <Configuration />
        } else if(page === 'ImportFiles') {
            return <ImportFiles setShowToast={setShowToast} setUploadedCount={setUploadedCount} setTotalFilesCount={setTotalFilesCount} setUploadSuccess={setUploadSuccess} />
        } else if(page === 'JobStatus') {
            return <JobStatus />
        } else if(page === 'Report') {
            return <Report />
        } else if(page === 'NotFound') {
            return <NotFound />
        } else {
            return <Login />
        }
    }

    const validatePages = (pages, componentName) => {
        if(pages[componentName].includes(userRole)) {
            return true;
        } else {
            return false;
        }
    }

    const PrivateRoute = ({ componentName, component, ...options }) => {
        let valid = validatePages(pages, componentName, component);
        const finalComponent = valid ? componentName : 'NotFound';
        return <Route {...options}  render={() => customRedirect(finalComponent)} />;
    };

    const logoutAction = () => {
        const logoutRequest = {
            postLogoutRedirectUri: "https://iconvert.zeiss.com"
        }
        instance.logoutRedirect(logoutRequest);
    };

    let connectionString = process.env.REACT_APP_APPLICATION_INSIGHT_CONNECTION_STRING;
    
    return (
        <>
            <AuthenticatedTemplate props>
                <Toast className='toast-container' onClose={() => setShowToast(false)} bg='success' show={showToast} delay={10000}>
                    <Toast.Header className={ uploadSuccess ? 'toast-header-new' : '' }>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        {!uploadSuccess && <strong className="me-auto">Uploading Files</strong>}
                        {uploadSuccess && <strong className="me-auto">Upload Successful</strong>}
                    </Toast.Header>
                    <Toast.Body>
                        {!uploadSuccess &&
                            <div>
                                Uploading {uploadedCount} file out of {totalFilesCount} files
                            </div>
                        }
                        {uploadSuccess &&
                            <div>
                                Job Uploaded successfully 
                            </div>
                        }
                    </Toast.Body>
                </Toast>
                <BrowserRouter>
                    <SessionTimeout isAuthenticated={true} logOut={logoutAction} />
                    <Route render={(props)=>(
                        <LayoutSSO role={userRole} {...props}>
                            <TelemetryProvider connectionString={connectionString} after={() => getAppInsights()}>
                                <Switch>
                                    {userRole === 'Tenant-Non-Admin' && 
                                        <PrivateRoute exact path="/" componentName="ImportFiles" component={ImportFiles} />
                                    }
                                    {userRole === 'Tenant-Admin' && 
                                        <PrivateRoute exact path="/" componentName="Users" component={Users} />
                                    }
                                    {(userRole === 'Zeiss-Admin' || userRole === 'ZEISS-Admin')  && 
                                        <PrivateRoute exact path="/" componentName="Tenants" component={Tenants} />
                                    }
                                    <PrivateRoute path='/organizations' componentName="Tenants" component={Tenants} />
                                    <PrivateRoute path='/users' componentName="Users" component={Users} />
                                    <PrivateRoute path='/configuration' componentName="Configuration" component={Configuration} />
                                    <PrivateRoute path='/import-files' componentName="ImportFiles" component={ImportFiles} setShowToast={setShowToast} setUploadedCount={setUploadedCount} setTotalFilesCount={setTotalFilesCount} setUploadSuccess={setUploadSuccess}/>
                                    <PrivateRoute path='/job-status' componentName="JobStatus" component={JobStatus} />
                                    <PrivateRoute path='/report' componentName="Report" component={Report} />
                                    <PrivateRoute path='*' component={NotFound} />
                                </Switch>
                            </TelemetryProvider>
                        </LayoutSSO>
                    )}/>
                </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <BrowserRouter>
                    <Route path='/' render={() => customRedirect('login')} />
                </BrowserRouter>
            </UnauthenticatedTemplate>
        </>
    )
}