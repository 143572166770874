import '../styles/Login.css';
import React, { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";

export const Login = () => {
    const { instance } = useMsal();

    useEffect(async() => {
        await instance.loginRedirect();
    });

    return (
        <>
            <div className='login-main-container'>
            </div>
        </>
    )
}